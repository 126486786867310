import React from "react"
import { StoryGroup } from "components"

const LinkMap = {
  "Technical Debt": "b5ec55b5159a",
  "Clean Code": "754fc7ccd5b8",
  Refactoring: "12a3af027843",
  "NextGen Enterprise": "57ceec47938e",
  "Techology Effect Arch": "5108c71fc623",
  "Organization Effect Arch": "2245fbf5bfca",
  "Min Viable Product": "308253337e4f",
  Mindmap: "77cab29df8bb",
  "Sketch, Wireframe, Mockup, Prototype": "394338279e0f",
  SOLID: "4991542a6b8d",
  "TDD,BDD": "30e225c29c14",
  "Reactive Manifesto": "113218b88ffd",
  "12 Factor App": "61645e68f114",
  "Jira Scrum Board": "58baeb72f973",
  "Pomodoro Technique": "bfc97456cb2e",
  ACID: "53f729f2bbb2",
  "CAP Theory": "8673e71c10fc",
  "ACID, BASE, CAP": "7764d6ebe239",
  RAID: "ead27c25bdae",
  BlockChain: "f245b6b69b00",
  "SoundCloud Microservice": "fe395b87600e",
  Dogfooding: "df0d26d4ba3",
}

const stories = [
  {
    title: "Teknik Borç (Technical Debt)",
    postId: "b5ec55b5159a",
  },
  {
    title: "Temiz Kod (Clean Code)",
    postId: "754fc7ccd5b8",
  },
  {
    title: "Refactoring",
    postId: "12a3af027843",
  },
  {
    title: "Gelecek Nesil Şirketlerin Teknolojileri",
    postId: "57ceec47938e",
  },
  {
    title: "Teknolojinin Yazılım Mimarisine Etkisi",
    postId: "5108c71fc623",
  },
  {
    title: "Organizasyon Yapısının Mimariye Etkisi",
    postId: "2245fbf5bfca",
  },
  {
    title: "MVP (Minimum Viable Product) Nedir?",
    postId: "308253337e4f",
  },
  {
    title: "Örnek Bir MindMap Çalışması",
    postId: "77cab29df8bb",
  },
  {
    title: "Sketch, Wireframe, Mockup, Prototype",
    postId: "394338279e0f",
  },
  {
    title: "S.O.L.I.D Prensipleri",
    postId: "4991542a6b8d",
  },
  {
    title: "TDD(Test) vs BDD(Behaviour)",
    postId: "30e225c29c14",
  },
  {
    title: "Reactive Manifesto",
    postId: "113218b88ffd",
  },
  {
    title: "12 Factor App Uygun Geliştirme",
    postId: "61645e68f114",
  },
  {
    title: "JIRA’daki Scrum Board’una Bakış",
    postId: "58baeb72f973",
  },
  {
    title: "Pomodoro Tekniği (Zaman Yönetimi)",
    postId: "bfc97456cb2e",
  },
  {
    title: "ACID",
    postId: "53f729f2bbb2",
  },
  {
    title: "CAP Teoremi",
    postId: "8673e71c10fc",
  },
  {
    title: "ACID, BASE, CAP",
    postId: "7764d6ebe239",
  },
  {
    title: "RAID",
    postId: "ead27c25bdae",
  },
  {
    title: "Blockchain",
    postId: "f245b6b69b00",
  },
  {
    title: "SoundCloud Mikroservis Geçişi",
    postId: "fe395b87600e",
  },
  {
    title: "Dogfooding",
    postId: "df0d26d4ba3",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "Mimari Kalite",
  path: "mimari-kalite",
}

const SoftwareDevCyclesPage = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default SoftwareDevCyclesPage
